import React from 'react';
import JobOffer from '@/components/modules/JobOffer';
import { LeverJobsByDepartments } from '../JobOffersSection/JobOffersSection.types';
import * as styles from './JobsOfferAccordions.module.scss';

export default function JobsOfferAccordions({
  entries,
  noOpeningsMessage,
}: {
  entries: Array<LeverJobsByDepartments>;
  noOpeningsMessage?: string;
}) {
  if (!entries || !entries?.length)
    return <div className={styles.noOpeningsMessage}>{noOpeningsMessage || 'No job openings found'}</div>;

  return (
    <div className="container p-0">
      <div className="row">
        <ul className="col-12 list-none">
          {entries.map(({ title, postings }) => {
            return (
              <li key={title} className="row mb-10">
                <div className="mb-10 col-md-4">
                  <h3 className={styles.title}>{title}</h3>
                </div>
                <ul className="list-none col-md-8">
                  {postings.map(jobOffer => (
                    <JobOffer key={jobOffer.id} {...jobOffer} />
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
