import React, { useState, KeyboardEvent } from 'react';
import Icon from '@/components/elements/Icon';
import * as styles from './Accordion.module.scss';

export default function Accordion({
  title,
  subtitle,
  children,
}: React.PropsWithChildren<{ title: string; subtitle: string }>) {
  const [isOpen, setIsOpen] = useState(false);
  const [exit, setExit] = useState(false);

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  function onClickHandler() {
    isOpen ? setExit(true) : toggleOpen();
  }
  function onAnimationEndHandler() {
    exit && toggleOpen();
    setExit(false);
  }

  function onKeyUpHandler(e: KeyboardEvent<HTMLDivElement>) {
    //enter, space
    if (e.which === 13 || e.which === 32) {
      onClickHandler();
    }
  }

  return (
    <section className={`pb-5 mb-5 ${styles.accordion}`}>
      <div role="button" tabIndex={0} onClick={onClickHandler} onKeyUp={onKeyUpHandler} className={styles.header}>
        <h4 className={`mb-2 ${styles.title}`}>{title}</h4>
        <p>{subtitle}</p>
        <Icon className={styles.icon}>{isOpen ? <>&#xf068;</> : <>&#xf067;</>}</Icon>
      </div>
      {isOpen && (
        <div className={`${styles.content} ${exit ? styles.exit : ''}`} onAnimationEnd={onAnimationEndHandler}>
          {children}
        </div>
      )}
    </section>
  );
}
