import React, { useContext } from 'react';
import Link from '@/components/elements/Link';
import Accordion from '@/components/modules/Accordion';
import { ConfigContext } from '@/base/context/Config/provider';
import type { LeverOffer, Salary } from '@/components/sections/JobOffersSection/JobOffersSection.types';
import * as styles from './JobOffer.module.scss';

export default function JobOffer({
  text,
  applyUrl,
  description,
  additional,
  lists,
  salaryDescription,
  salaryRange,
  categories: { commitment, location, team },
}: LeverOffer) {
  const {
    state: { config },
  } = useContext(ConfigContext);

  function renderInterval(interval: string) {
    if (!interval) return null;
    if (interval.toLowerCase().includes('year')) return ' a year';
    return null;
  }

  function renderSalaryBlock({ currency, interval, max, min }: Salary) {
    if (!min || !max || !currency) return null;

    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    });

    const content =
      max === min
        ? currencyFormatter.format(min)
        : `${currencyFormatter.format(min)} - ${currencyFormatter.format(max)}`;

    return (
      <p className={styles.salary}>
        {content} {renderInterval(interval)}
      </p>
    );
  }

  return (
    <li>
      <Accordion title={text} subtitle={`${location} | ${team} | ${commitment}`}>
        <div className={styles.accordionContent}>
          <div dangerouslySetInnerHTML={{ __html: description }} />

          {lists &&
            lists.map(({ content, text }) => (
              <>
                <h3 className={styles.accordionListHeading}>{text}</h3>
                <ul dangerouslySetInnerHTML={{ __html: content }} className={styles.accordionList} />
              </>
            ))}

          {salaryRange ? renderSalaryBlock(salaryRange) : null}

          {salaryDescription && <div dangerouslySetInnerHTML={{ __html: salaryDescription }} />}

          <div dangerouslySetInnerHTML={{ __html: additional }} />

          <Link
            display_arrow
            arrow_direction="right"
            type="external"
            href={applyUrl}
            text={config?.careers_post_link_label}
            wrapperClassName="mt-5"
          />
        </div>
      </Accordion>
    </li>
  );
}
