import React, { useState, useEffect, ChangeEvent } from 'react';
import { LEVER_POSTINGS_ENDPOINT } from '@/base/consts/endpoints';
import Select from '@/components/elements/Select';
import SearchInput from '@/components/elements/SearchInput';
import JobsOfferAccordions from '../JobsOfferAccordions';
import {
  CategoriesState,
  Category,
  JobOffersSectionTypes,
  LeverJobsByDepartments,
  LeverOffer,
} from './JobOffersSection.types';
import * as styles from './JobOffersSection.module.scss';
import { INITIAL_CATEGORIES_STATE } from './JobOfferSection.const';

export default function JobOffersSection({ title, placeholder, noOpeningsMessage }: JobOffersSectionTypes) {
  const [jobOffers, setJobOffers] = useState<Array<LeverOffer>>([]);
  const [jobCategories, setJobCategories] = useState<CategoriesState>(INITIAL_CATEGORIES_STATE);
  const [inputValue, setInputValue] = useState<string>('');

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    return setInputValue(e.target.value.toLowerCase());
  }

  function onSelectChange(name: string, selected: Category) {
    return setJobCategories(prevState => ({
      ...prevState,
      [name]: { ...prevState[name], selected: selected },
    }));
  }

  function findOffersWithCorrectFilters(leverData: LeverOffer[]) {
    return leverData.filter(leverJob => {
      const correctFilters = offerIncludesFilters(leverJob).includes(false);
      if (!correctFilters && leverJob.text.toLowerCase().includes(inputValue)) return leverJob;
    });
  }

  function offerIncludesFilters(leverOffer: LeverOffer) {
    return Object.keys(leverOffer.categories).map(categoryName => {
      if (categoryName === 'team') return null;
      if (jobCategories[categoryName].selected?.value.includes('All')) return true;
      return leverOffer.categories[categoryName] === jobCategories[categoryName].selected?.value;
    });
  }

  function transformLeverResponse(leverData: LeverOffer[], state: CategoriesState) {
    leverData.forEach(leverJob => {
      Object.keys(leverJob.categories).forEach(categoryName => {
        const categoryValue = leverJob.categories[categoryName];
        if (!categoryValue) return;

        const isUniqOptionName = !state[categoryName].options.find(option => {
          return option.name === categoryValue;
        });

        if (!isUniqOptionName) return;

        state[categoryName].options.push({
          name: categoryValue,
          value: categoryValue,
          id: categoryValue,
        });

        state[categoryName].selected = state[categoryName].options[0];
      });
    });

    const { team, ...validFilters } = state;
    return validFilters;
  }

  function groupByDepartments(jobOffers: LeverOffer[]) {
    const groupedOffers = jobOffers.reduce((prevValue, currentValue) => {
      const department = currentValue.categories.department || 'Other';
      if (!prevValue?.[department])
        return { ...prevValue, [department]: { title: department, postings: [currentValue] } };

      return {
        ...prevValue,
        [department]: { title: department, postings: [...prevValue[department].postings, currentValue] },
      };
    }, {} as { [key: string]: LeverJobsByDepartments });

    return Object.values(groupedOffers);
  }

  useEffect(() => {
    fetch(LEVER_POSTINGS_ENDPOINT)
      .then(res => res.json() as Promise<LeverOffer[]>)
      .then(jobOffers => {
        // Filter out 'allLocations' from each offer's categories
        const filteredJobOffers = jobOffers.map(offer => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { allLocations, ...categories } = offer.categories;
          return { ...offer, categories };
        });
        setJobCategories(transformLeverResponse(filteredJobOffers, jobCategories));
        setJobOffers(filteredJobOffers);
      });
  }, []);

  if (!jobOffers.length) return null;

  return (
    <section className={styles.wrapper} id="job_offers">
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.topBar}>
        <div className={styles.inputContainer}>
          <div className={styles.inputWrapper}>
            <SearchInput value={inputValue} onChange={onInputChange} name="search" placeholder={placeholder} />
          </div>
        </div>
        {jobCategories && (
          <ul className={styles.selectsList}>
            {Object.entries(jobCategories).map(([name, { options, selected }]) => (
              <li key={name} className={styles.selectsListItem}>
                <Select options={options} name={name} selected={selected} onChange={onSelectChange} />
              </li>
            ))}
          </ul>
        )}
      </div>
      {jobOffers && (
        <JobsOfferAccordions
          entries={groupByDepartments(findOffersWithCorrectFilters(jobOffers))}
          noOpeningsMessage={noOpeningsMessage}
        />
      )}
    </section>
  );
}
