import { CategoriesState } from './JobOffersSection.types';

export const INITIAL_CATEGORIES_STATE: CategoriesState = {
  team: {
    options: [
      {
        name: 'All teams',
        value: 'All teams',
        id: 'All teams',
      },
    ],
    selected: null,
  },
  location: {
    options: [
      {
        name: 'All locations',
        value: 'All locations',
        id: 'All locations',
      },
    ],
    selected: null,
  },
  department: {
    options: [
      {
        name: 'All departments',
        value: 'All departments',
        id: 'All departments',
      },
    ],
    selected: null,
  },
  commitment: {
    options: [
      {
        name: 'All commitments',
        value: 'All commitments',
        id: 'All commitments',
      },
    ],
    selected: null,
  },
};
