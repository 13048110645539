// extracted by mini-css-extract-plugin
export var bold = "JobsOfferAccordions-module--bold--3e793";
export var noOpeningsMessage = "JobsOfferAccordions-module--noOpeningsMessage--7f18f";
export var opacityEnter = "JobsOfferAccordions-module--opacityEnter--3fb9a";
export var opacityExit = "JobsOfferAccordions-module--opacityExit--b98d8";
export var paragraph = "JobsOfferAccordions-module--paragraph--e3de3";
export var rollDown = "JobsOfferAccordions-module--rollDown--a4255";
export var rollUp = "JobsOfferAccordions-module--rollUp--61856";
export var slideInDown = "JobsOfferAccordions-module--slideInDown--06c93";
export var slideOutUp = "JobsOfferAccordions-module--slideOutUp--b5497";
export var splashEnter = "JobsOfferAccordions-module--splashEnter--7cc56";
export var splashExit = "JobsOfferAccordions-module--splashExit--cc142";
export var title = "JobsOfferAccordions-module--title--037ef";