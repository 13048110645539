// extracted by mini-css-extract-plugin
export var accordionContent = "JobOffer-module--accordionContent--75eeb";
export var accordionList = "JobOffer-module--accordionList--813e9";
export var accordionListHeading = "JobOffer-module--accordionListHeading--37b23";
export var opacityEnter = "JobOffer-module--opacityEnter--9be33";
export var opacityExit = "JobOffer-module--opacityExit--d69b1";
export var rollDown = "JobOffer-module--rollDown--8162b";
export var rollUp = "JobOffer-module--rollUp--a4be1";
export var salary = "JobOffer-module--salary--d8605";
export var slideInDown = "JobOffer-module--slideInDown--f2f1b";
export var slideOutUp = "JobOffer-module--slideOutUp--dcca2";
export var splashEnter = "JobOffer-module--splashEnter--3708a";
export var splashExit = "JobOffer-module--splashExit--20fea";