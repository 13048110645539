import React, { useState } from 'react';
import { ReactComponent as ArrowSelect } from '@/assets/icons/arrow.svg';
import type { SelectTypes } from './Select.types';
import * as styles from './Select.module.scss';

export default function Select({ name, options, selected, onChange }: SelectTypes) {
  const [optionsVisibility, changeVisibility] = useState(false);

  const toggleVisibility = (e: React.SyntheticEvent) => {
    e.preventDefault();
    changeVisibility(!optionsVisibility);
  };

  if (!options) return null;
  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        className={styles.mainOption}
        onClick={toggleVisibility}
        onKeyDown={toggleVisibility}
      >
        <div className={styles.labelWrapper} data-open={String(optionsVisibility)}>
          <p className={styles.label}>{selected?.name}</p>
          <div className={`${styles.arrow} ${optionsVisibility ? styles.arrowOpen : ''}`}>
            <ArrowSelect />
          </div>
        </div>
        <div className={`${styles.optionsWrapper} ${optionsVisibility ? `flex ${styles.closestLayer}` : `hidden`}`}>
          {options.map(option => (
            <button
              key={option.value}
              className={styles.option}
              onClick={() => onChange(name, option)}
              value={option.value}
              data-selected={String(option.value === selected?.value)}
            >
              <p>{option.name}</p>
            </button>
          ))}
        </div>
      </div>
      {optionsVisibility && (
        <div
          role="button"
          tabIndex={-1}
          className={styles.backdrop}
          onClick={toggleVisibility}
          onKeyDown={toggleVisibility}
        />
      )}
    </>
  );
}
