import React from 'react';
import { SearchInputTypes } from './SearchInput.types';
import * as styles from './SearchInput.module.scss';

export default function SearchInput({ name, id, value, onChange, placeholder, error = false }: SearchInputTypes) {
  return (
    <div className={styles.wrapper}>
      <input
        name={name}
        id={id || name}
        className={`input ${error ? 'input--error' : ''} ${styles.search}`}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}
