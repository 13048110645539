// extracted by mini-css-extract-plugin
export var opacityEnter = "SearchInput-module--opacityEnter--21d73";
export var opacityExit = "SearchInput-module--opacityExit--e8f2f";
export var rollDown = "SearchInput-module--rollDown--583c8";
export var rollUp = "SearchInput-module--rollUp--6d6a7";
export var search = "SearchInput-module--search--6f514";
export var slideInDown = "SearchInput-module--slideInDown--c9c55";
export var slideOutUp = "SearchInput-module--slideOutUp--7fb6b";
export var splashEnter = "SearchInput-module--splashEnter--ce0ba";
export var splashExit = "SearchInput-module--splashExit--353a4";
export var wrapper = "SearchInput-module--wrapper--ba6d4";